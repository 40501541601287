<template>
  <el-dialog
    class="zyq55_dialog"
    title="提示"
    :visible.sync="dialogVisible"
    width="450px"
    :before-close="handleClose"
  >
    <div class="dialog_center">
      <i class="hy-icon-icon" style="color: #2079fb;font-size:20px"></i>&nbsp;&nbsp;提交成功等待平台审核!
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleClose">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props:{
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false
    };
  },
  watch: {
    visible() {
      this.dialogVisible = this.visible
    }
  },
  methods: {
    handleClose() {
      this.$emit("update:visible",false)
    },
  },
};
</script>
<style lang="scss">
.zyq55_dialog {
  .el-dialog {
    border-radius: 10px;
  }
  .el-dialog__body {
    padding: 30px 27px 11px 20px;
  }
  .el-dialog__header {
    height: 53px;
    padding: 19px 25px;
    background: #f8f8f8;
    border-radius: 10px;
    .el-dialog__title {
      font-size: 16px;
      line-height: 16px;
      color: #666666;
    }
  }
  .el-dialog__footer {
    padding: 18px 25px 18px;
    .el-button--primary {
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
  }
  .dialog_center {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    height: 26px;
  }
}
</style>
<style>
</style>